import React from "react";

import {
    Button,
    Container,
    Row,
    Col
} from "reactstrap";

class DetailReminder extends React.Component {

    render() {
        return (
            <div className="section section-gold text-center">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h2 className="title">Intelligent Reminders</h2>
                            <h5 className="description">
                                asdf
                            </h5>
                            <br />
                            <Button
                                className="btn-round"
                                color="info"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                ...
                            </Button>
                        </Col>
                    </Row>

                </Container>
            </div>
        )
    }
}

export default DetailReminder;