//import app from 'firebase/app';
import * as firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import FirebaseContext, {withFirebase} from './context';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

class Firebase {
    constructor() {
      firebase.initializeApp(config);
      this.auth = firebase.auth();
      this.fs = firebase.firestore();
    }

    // ************************************ //
    // ******** Authentication API ******** //
    // ************************************ //

    doSignInWithEmailAndPassword = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => 
      this.auth.signOut();

    // ****************************************** //
    // ****** Firestore - Mailing List API ****** //
    // ****************************************** //

    // Create Mailing List entry
    doAddUserMailingList = (
        firstName,
        lastName,
        email,
        comments
    ) => {
        return this.fs.collection('mailinglist').add({
            firstName: firstName,
            lastName: lastName,
            email: email,
            isActive: true,
            comments: comments
        })
    }

}

export default Firebase;
export { FirebaseContext, withFirebase };