import React from "react";

import {
    Button,
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardTitle
} from "reactstrap";

class ValueProp extends React.Component {

    render() {
        return (
            <div className="section section-dark-blue text-center">
                <Container>
                    <h2 className="title">What can we do for you?</h2>
                    <Row>
                        <Col md="4">
                            <Card className="card-profile card-plain">
                                <div className="info">
                                    <div className="icon icon-info">
                                        <i className="nc-icon nc-touch-id" />
                                    </div>
                                </div>
                                <CardBody>
                                    <a href="#blog" onClick={e => e.preventDefault()}>
                                        <div className="author">
                                            <CardTitle tag="h4">Unique & Personal</CardTitle>
                                            <h6 className="card-category">Keep your data for life</h6>
                                        </div>
                                    </a>
                                    <p className="card-description text-center">
                                        Your career is yours and only yours. It is up to you to set relevant and smart goals to maximize your growth and earning potentital. We help you set a path that is right for you and help you stay committed.
                                    </p>
                                    <p className="card-description text-center">
                                        Also, one of our core beliefs is that you own your data. So it doesn't matter if you move in your career, you can take your goals and accomplishments with you.
                                    </p>
                                </CardBody>
                                <CardFooter className="text-center">
                                    <Button className="btn-neutral" color="link" href="#blog">
                                        Learn more
                                </Button>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-profile card-plain">
                                <div className="info">
                                    <div className="icon icon-info">
                                        <i className="nc-icon nc-chat-33" />
                                    </div>
                                </div>
                                <CardBody>
                                    <a href="#blog" onClick={e => e.preventDefault()}>
                                        <div className="author">
                                            <CardTitle tag="h4">Feedback & Mentorship</CardTitle>
                                            <h6 className="card-category">Set SMART goals for your future</h6>
                                        </div>
                                    </a>
                                    <p className="card-description text-center">
                                        Metrics are only useful if you can derive actions and measure progress. Feedback is only valuable if it is honest and traceable. CMD provides an effortless way to track and use both through the ability to request customized feedback from your peers and maintain mentorship relationships in a single app.
                                </p>
                                </CardBody>
                                <CardFooter className="text-center">
                                    <Button className="btn-neutral" color="link" href="#blog">
                                        Learn more
                                </Button>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-profile card-plain">
                                <div className="info">
                                    <div className="icon icon-info">
                                        <i className="nc-icon nc-time-alarm" />
                                    </div>
                                </div>
                                <CardBody>
                                    <a href="#blog" onClick={e => e.preventDefault()}>
                                        <div className="author">
                                            <CardTitle tag="h4">Intelligent Reminders</CardTitle>
                                            <h6 className="card-category">Stay on top of action items</h6>
                                        </div>
                                    </a>
                                    <p className="card-description text-center">
                                        Good habits are ciritical to success. However, good things don't come easy. We strive to make this easier by creating intelligent reminders and easy interactions so that you can focus less on remember what to do and more on doing.
                                </p>
                                </CardBody>
                                <CardFooter className="text-center">
                                    <Button className="btn-neutral" color="link" href="#blog">
                                        Learn more
                                </Button>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default ValueProp;