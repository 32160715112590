import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";

import * as ROUTES from 'constants/routes';

// reactstrap components
import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container
} from "reactstrap";

function TopNav() {
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [navbarCollapse, setNavbarCollapse] = React.useState(false);

    const toggleNavbarCollapse = () => {
        setNavbarCollapse(!navbarCollapse);
        document.documentElement.classList.toggle("nav-open");
    };

    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 299 ||
                document.body.scrollTop > 299
            ) {
                setNavbarColor("");
            } else if (
                document.documentElement.scrollTop < 300 ||
                document.body.scrollTop < 300
            ) {
                setNavbarColor("navbar-transparent");
            }
        };

        window.addEventListener("scroll", updateNavbarColor);

        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });

    return (
        <Navbar
            className={classnames("fixed-top", navbarColor)}
            color-on-scroll="300"
            expand="lg"
        >
            <Container>
                <div className="navbar-translate">
                    <NavbarBrand
                        data-placement="bottom"
                        to="/"
                        href={ROUTES.LANDING}
                        target="_self"
                    >
                        CMD Careers
                    </NavbarBrand>
                    <button
                        aria-expanded={navbarCollapse}
                        className={classnames("navbar-toggler navbar-toggler", {
                            toggled: navbarCollapse
                        })}
                        onClick={toggleNavbarCollapse}
                    >
                        <span className="navbar-toggler-bar bar1" />
                        <span className="navbar-toggler-bar bar2" />
                        <span className="navbar-toggler-bar bar3" />
                    </button>
                </div>
                <Collapse
                    className="justify-content-end"
                    navbar
                    isOpen={navbarCollapse}
                >
                    <Nav navbar>
                        {/* FEATURE FLAG: Remove when ready for prod */}
                        {process.env.REACT_APP_ENV === "dev"? 
                            <>
                                <NavItem>
                                    <NavLink
                                        href={ROUTES.DASHBOARD}
                                        target="_self"
                                    >
                                        <i className="nc-icon nc-layout-11" /> CMD Console
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="https://blog.cmd.careers"
                                        target="_blank"
                                    >
                                        CMD Blog
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="/pricing"
                                        target="_self"
                                    >
                                        Pricing
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        data-placement="bottom"
                                        href="https://twitter.com/"
                                        target="_blank"
                                        title="Follow us on Twitter"
                                    >
                                        <i className="fa fa-twitter" />
                                        <p className="d-lg-none">Twitter</p>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        data-placement="bottom"
                                        href="https://www.facebook.com/"
                                        target="_blank"
                                        title="Like us on Facebook"
                                    >
                                        <i className="fa fa-facebook-square" />
                                        <p className="d-lg-none">Facebook</p>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        data-placement="bottom"
                                        href="https://www.instagram.com/"
                                        target="_blank"
                                        title="Follow us on Instagram"
                                    >
                                        <i className="fa fa-instagram" />
                                        <p className="d-lg-none">Instagram</p>
                                    </NavLink>
                                </NavItem>
                            </>
                        :null}
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default TopNav;
