import React from "react";

import TopNav from "components/navigation/TopNav";
import MainHeader from "components/sections/MainHeader";
import Footer from "components/footer/Footer";
import Intro from "components/sections/Intro";
import ValueProp from "components/sections/ValueProp";
import SignUp from "components/sections/SignUp";
import DetailPersonal from "components/sections/DetailPersonal";
import DetailMentorship from "components/sections/DetailMentorship";
import DetailReminder from "components/sections/DetailReminder";

class LandingPage extends React.Component {
    // constructor(props){
    //     super(props);
    // }

    componentDidMount() {
        document.documentElement.classList.remove("nav-open");
        // React.useEffect(() => {
        //   document.body.classList.add("profile-page");
        //   return function cleanup() {
        //     document.body.classList.remove("profile-page");
        //   };
        // });
    }

    render() {
        return (
            <>
                <TopNav />
                <MainHeader />
                <div className="main">
                    <Intro />
                    <ValueProp />
                    {/* REMOVE FEATUER FLAG when ready in for production */}
                    {process.env.REACT_APP_ENV === "dev"?
                        <>
                            <DetailPersonal />
                            <DetailMentorship />
                            <DetailReminder />
                        </>
                    :null}
                    <SignUp />
                </div>
                <Footer />
            </>
        );
    }
}

export default LandingPage;
