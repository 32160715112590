import React from "react";
import { 
  Row, 
  Container 
} from "reactstrap";

class Footer extends React.Component {
  // constructor(props){
  //     super(props);
  // }

  render () {
    return (
      <footer className="footer footer-black footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                {/* FEATURE FLAG: Remove when ready for prod */}
                {process.env.REACT_APP_ENV === "dev"? 
                    <>
                        <li>
                            <a
                                href="http://blog.cmd.careers"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                CMD Blog
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://console.cmd.careers"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                CMD Console
                            </a>
                        </li>
                    </>
                :null}
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                © {/* new Date().getFullYear() */} CMD.Careers 
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
