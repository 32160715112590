import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Firebase, {FirebaseContext} from './components/firebase/firebase';
import { createBrowserHistory } from "history";

import MainRoutes from "./routes";

import "./assets/css/bootstrap.min.css";
import "./assets/scss/paper-kit.scss";
import "./assets/demo/demo.css";

const hist = createBrowserHistory();

ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <MainRoutes hist={hist} />
    </FirebaseContext.Provider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();