import React from "react";
import { Button, Container } from "reactstrap";

// core components

class MainHeader extends React.Component {

  // let pageHeader = React.createRef();

  // React.useEffect(() => {
  //   if (window.innerWidth < 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageHeader.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });

  render() {
    return (
      <>
        <div
          style={{
            backgroundImage: "url(https://images.unsplash.com/photo-1505855265981-d52719d1f64e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1901&q=80)"
          }}
          className="page-header"
          // data-parallax={true}
          // ref={pageHeader}
        >
          <div className="filter" />
          <Container>
            <div className="motto text-center">
              <h1>IT'S YOUR CAREER</h1>
              <h3>CMD puts you back in the driver seat.</h3>
              <br />
              <br />
              <Button className="btn-round" color="neutral" type="button" outline>
                Join the revolution
              </Button>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default MainHeader;
