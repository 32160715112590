import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import TopNav from "components/navigation/TopNav.js";
import Footer from "components/footer/Footer.js";

function PricingPage() {
  document.documentElement.classList.remove("nav-open");
  // React.useEffect(() => {
  //   document.body.classList.add("profile-page");
  //   return function cleanup() {
  //     document.body.classList.remove("profile-page");
  //   };
  // });
  return (
    <>
      <TopNav />
      <div className="main">
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title">Pricing</h2>
            <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="info">
                    <div className="icon icon-info">
                      <i className="nc-icon nc-sun-fog-29" />
                    </div>
                  </div>
                  <CardBody>
                    <a href="#blog" onClick={e => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Free</CardTitle>
                        <h6 className="card-category">Individual</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quas
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button className="btn-neutral" color="link" href="#blog">
                      See more
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="info">
                    <div className="icon icon-info">
                      <i className="nc-icon nc-sun-fog-29" />
                    </div>
                  </div>
                  <CardBody>
                    <a href="#blog" onClick={e => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">$20 / year</CardTitle>
                        <h6 className="card-category">Premium</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quas
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button className="btn-neutral" color="link" href="#blog">
                      See more
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="info">
                    <div className="icon icon-info">
                      <i className="nc-icon nc-sun-fog-29" />
                    </div>
                  </div>
                  <CardBody>
                    <a href="#blog" onClick={e => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">$5/month per user</CardTitle>
                        <h6 className="card-category">Organization</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quas
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button className="btn-neutral" color="link" href="#blog">
                      See more
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PricingPage;
