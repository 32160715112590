import React from "react";
// import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import {withAuthentication} from 'components/session/Session';

import LandingPage from './views/Landing';
import PricingPage from './views/Pricing';


class MainRoutes extends React.Component {
    // constructor(props){
    //   super(props);
    // }

    render() {
        return (
            <Router history={this.props.hist}>
                <Switch>
                    <Route exact path="/" render={props => <LandingPage {...props} />} />
                    <Route path="/pricing" render={props => <PricingPage {...props} />} />
                    <Redirect to="/" />
                </Switch>
            </Router>
        )
    }
}
    // <Route path="/feedback/:docid" render={props => <StandaloneFeedbackLayout {...props} />} 

export default MainRoutes; 
//export default withAuthentication(MainRoutes);