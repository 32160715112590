import React from "react";
import { withFirebase } from 'components/firebase/firebase';

import {
    Button,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Alert
} from "reactstrap";

class SignUp extends React.Component {
    constructor(props){
        super(props);
    
        this.state = {
            backgroundColor: "blue",
            signupFirstName: '',
            signupLastName: '',
            signupEmail: '',
            signupFeedback: '',
            validToSubmit: false,
            isChanged: false,
            submitSuccess: false,
            message: null,
        };
    }

    // emailSignup
    onEmailSignupSubmit = event => {
        if (!this.state.validToSubmit || !this.state.isChanged) {
            this.setState({message: "Oh no! Please double check email format."});
            /// TODO: Update the tyling of the success and ailure messages
        } else {
            this.props.firebase
                .doAddUserMailingList (
                    this.state.signupFirstName,
                    this.state.signupLastName,
                    this.state.signupEmail,
                    this.state.signupFeedback
                ).then(() => {
                    this.setState({submitSuccess: true});
                })
                .catch(error => {
                    console.log(error)
                    this.setState({ error, message: "Oh no! Something went wrong. Please try again later." });
                });
            
            /// TODO: Update the tyling of the success and ailure messages
        }

        event.preventDefault();
    }

    // On change of the fields, update the state
    onChange = event => {
        event.persist();
        
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.setState({validToSubmit: this.validateEmail(this.state.signupEmail),
                            isChanged: this.state.signupEmail !== ''});
        });
    };

    validateEmail = email => {
        let isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(email);
        return isValid;
    }



    
    render() {
        return (

            <div className="section landing-section">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h2 className="text-center">Alpha Coming Soon!</h2>
                            <Form onSubmit={this.onEmailSignupSubmit} className="contact-form">
                                <Row>
                                    <Col md="12">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="nc-icon nc-email-85" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                id="signup-email"
                                                name = "signupEmail"
                                                value = {this.state.signupEmail}
                                                onChange = {e => this.onChange(e)}
                                                onBlur = {e => this.onChange(e)}
                                                placeholder="Email (Required)"
                                                type = "text"
                                                disabled = {this.state.submitSuccess}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row><br />
                                <Row>
                                    <Col md="6">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="nc-icon nc-single-02" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                id="signup-firstname"
                                                name = "signupFirstName"
                                                value = {this.state.signupFirstName}
                                                onChange = {e => this.onChange(e)}
                                                onBlur = {e => this.onChange(e)}
                                                placeholder="First Name"
                                                type = "text"
                                                disabled = {this.state.submitSuccess}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md="6">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="nc-icon nc-single-02" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                id="signup-lastname"
                                                name = "signupLastName"
                                                value = {this.state.signupLastName}
                                                onChange = {e => this.onChange(e)}
                                                onBlur = {e => this.onChange(e)}
                                                placeholder="Last Name"
                                                type = "text"
                                                disabled = {this.state.submitSuccess}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row><br />
                                <Input
                                    id="signup-feedback"
                                    name = "signupFeedback"
                                    value = {this.state.signupFeedback}
                                    onChange = {e => this.onChange(e)}
                                    onBlur = {e => this.onChange(e)}
                                    placeholder="Share your throughts! Let us know what you are most looking forward to."
                                    type = "textarea"
                                    rows="4"
                                    disabled = {this.state.submitSuccess}
                                />
                                {this.state && this.state.submitSuccess?
                                    <>
                                        <br />
                                        <Alert color="success">
                                            <center>Thank you! We'll keep you updated with exciting updates.</center>
                                        </Alert>
                                    </>
                                    :
                                    <>
                                        <Row>
                                            <Col className="ml-auto mr-auto" md="4">
                                                <Button className="btn-fill" color="danger" size="lg">
                                                    Keep in touch!
                                                </Button>
                                            </Col>
                                        </Row>
                                        {this.state && this.state.message !== null?
                                            <>
                                                <br />
                                                <Alert color="danger">
                                                    <center>{this.state && this.state.message}</center>
                                                </Alert>
                                            </>
                                            : null
                                        }
                                    </>
                                }
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default withFirebase(SignUp);