import React from "react";

import {
    Button,
    Container,
    Row,
    Col
} from "reactstrap";

class Intro extends React.Component {

    render() {
        return (
            <div className="section text-center">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h2 className="title">Real talk</h2>
                            <h5 className="description">
                                For far too long, have careers been driven from the top down. Businesses create career paths that are catered towards their needs with little to no clarity on how to advance or what path is even right for you.<br /><br />We believe that your career is about how you grow in life, not just at a company. As such, your goals and achivements should travel with you and evolve over time.
                            </h5>
                            <br />
                            <Button
                                className="btn-round"
                                color="info"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                            >
                                Read our blog post...
                            </Button>
                        </Col>
                    </Row>

                </Container>
            </div>
        )
    }
}

export default Intro;